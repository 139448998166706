import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, A11y } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const IndexPage = () => {
  const [p, setP] = useState([])
  const [region, setRegion] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    const getPrices = async () => {
      setIsLoading(true)
      const response = await fetch(`/api/latest-prices?region=${region ? 'inland' : 'coastal'}`)
      const prices = await response.json()
      const data = transformPrices(groupAndSortData(prices))
      setP(data)
      setIsLoading(false)
    }

    getPrices()
  }, [region])

  const handleRegionToggle = () => {
    setRegion(!region)
  }

  
  return (
    <>
      <section className='prices-carousel'>
        <Swiper
          modules={[Navigation, Pagination, A11y]}
          spaceBetween={50}
          slidesPerView={1}
          navigation
          pagination={{ clickable: true }}
          className='carousel'
        >
          {p.map((price, index) => {
            if (isLoading) {
              return(
                <SwiperSlide key={index}>
                  <div className='carousel__slide'>
                    <div className="carousel__slide--banner">
                      <div className='carousel__slide--title'>
                        <p>Loading</p>
                      </div>
                      <div className="region-toggle" onClick={handleRegionToggle}>
                        <span className={region ? 'active' : ''}>Inland</span>
                        <span className={!region ? 'active' : ''}>Coastal</span>
                      </div>
                    </div>
                    <p className='carousel__slide--cDate'>Loading</p>
                    <p className='carousel__slide--cPrice'>Loading</p>
                    <div className="carousel__slide--temporal">
                      <div className="carousel__slide--p">
                        <p className='carousel__slide--p--title'>Loading</p>
                        <p className='carousel__slide--p--date'>Loading</p>
                        <p className='carousel__slide--p--price'>Loading</p>
                      </div>
                      <div className="carousel__slide--u">
                        <p className='carousel__slide--u'>Loading</p>
                        <p className='carousel__slide--u--date'>Loading</p>
                        <p className='carousel__slide--u--price'>Loading</p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              )
            }
            if (!isLoading) {
              return(
                <SwiperSlide key={index}>
                  <div className='carousel__slide'>
                    <div className="carousel__slide--banner">
                      <div className='carousel__slide--title'>
                        <p>{price.fuel.toUpperCase()}</p>
                      </div>
                      <div className="region-toggle" onClick={handleRegionToggle}>
                        <span className={region ? 'active' : ''}>Inland</span>
                        <span className={!region ? 'active' : ''}>Coastal</span>
                      </div>
                    </div>
                    <p className='carousel__slide--cDate'>{formatDate(price.cDate)}</p>
                    <p className='carousel__slide--cPrice'>R {price.cPrice.toFixed(2)}</p>
                    <div className="carousel__slide--temporal">
                      <div className="carousel__slide--p">
                        <p className='carousel__slide--p--title'>Previous</p>
                        <p className='carousel__slide--p--date'>{formatDate(price.pDate)}</p>
                        <p className='carousel__slide--p--price'>R {price.pPrice.toFixed(2)}</p>
                      </div>
                      <div className="carousel__slide--u">
                        <p className='carousel__slide--u'>Upcoming</p>
                        <p className='carousel__slide--u--date'>{price.uDate != null ? formatDate(price.uDate) : '-- --- ----'}</p>
                        <p className='carousel__slide--u--price'>R {price.uPrice != null ? price.uPrice.toFixed(2) : '--.--'}</p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              )
            }
          })}
        </Swiper>

        <div className="actions">
          <a href="#prices" className='btn-primary'>View All Prices</a>
          <button className='btn-secondary' onClick={async () => {
            try {
              await navigator.share({
                title: "Up to Date Fuel Prices | DLR",
                url: "https://daylightrobbery.co.za",
              });
              console.log("MDN shared successfully");
            } catch (err) {
              console.log(`Error: ${err}`);
            }
          }}>Share</button>
        </div>
      </section> 

      <section className='full-width bg-primary' id='prices'>
        <h2>Prices Table</h2>
        <div className="price-table">
          {p.map((price, index) => {
            return (
              <div className='price-table__row'>
                <p className='price-table__row--title'>{toTitleCase(price.fuel)}</p>
                <div className='price-table__row--data--current'>
                  <p>Current</p>
                  <p>{formatDate(price.cDate)}</p>
                  <p>R {price.cPrice.toFixed(2)}</p>
                </div>
                <div className='price-table__row--data--previous'>
                  <p>Previous</p>
                  <p>{formatDate(price.pDate)}</p>
                  <p>R {price.pPrice.toFixed(2)}</p>
                </div>
                <div className='price-table__row--data--upcoming'>
                  <p>Upcoming</p>
                  <p>{price.uDate != null ? formatDate(price.uDate) : '-- --- ----'}</p>
                  <p>R {price.uPrice != null ? price.uPrice.toFixed(2) : '--.--'}</p>
                </div>
              </div>
            )
          })}
        </div>
      </section>
    </>
  )
}

const groupAndSortData = (data) => {
  // Helper function to convert Firestore timestamp to JavaScript Date
  const convertToDate = (firestoreTimestamp) => {
      return new Date(firestoreTimestamp._seconds * 1000);
  };

  // Grouping the data
  const groupedData = data.reduce((acc, item) => {
      const key = `${item.fuel}_${item.type}`;
      if (!acc[key]) {
          acc[key] = [];
      }
      acc[key].push({ ...item, date: convertToDate(item.date) });
      return acc;
  }, {});

  // Sorting each group by date
  for (const key in groupedData) {
      groupedData[key].sort((a, b) => a.date - b.date);
  }

  return groupedData;
}

const transformPrices = (prices) => {
  const fuelTypes = new Array()

  const arr = Object.entries(prices)

  for (const type of arr) {
    const obj = new Object()
    obj.fuel = type[1][0].fuel
    obj.type = type[1][0].type
    obj.region = type[1][0].region
    if (new Date(type[1][2].date) > new Date()) {
      obj.cDate = new Date(type[1][1].date)
      obj.uDate = new Date(type[1][2].date)
      obj.pDate = new Date(type[1][0].date)

      obj.cPrice = type[1][1].price
      obj.uPrice = type[1][2].price
      obj.pPrice = type[1][0].price
    } else {
      obj.cDate = new Date(type[1][2].date)
      obj.uDate = null
      obj.pDate = new Date(type[1][1].date)

      obj.cPrice = type[1][2].price
      obj.uPrice = null
      obj.pPrice = type[1][1].price
    }

    fuelTypes.push(obj)
  }

  return fuelTypes
}

const formatDate = (date) => {
  const day = ('0' + date.getDate()).slice(-2); // Pad with leading zero
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const month = months[date.getMonth()]; // Convert month number to month name
  const year = date.getFullYear();

  return `${day} ${month} ${year}`;
}

const toTitleCase = (str) => {
    if (!str) return str; // Handle empty string case
  
    return str.replace(/\b\w/g, match => match.toUpperCase());
}

export default IndexPage