import React from 'react'

const Footer = () => {
  return (
    <footer className='content-grid dark'>
      <div className="logo">© Daylight Robbery {new Date().getFullYear()}</div>
    </footer>
  )
}

export default Footer