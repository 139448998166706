import './App.css';
import {Routes, Route} from 'react-router-dom'
import IndexPage from './pages/IndexPage';
import Layout from './components/Layout';

function App() {
  return (
    <Routes>
      <Route path='/' element={<Layout />}>
        <Route path='/' element={<IndexPage />} />
      </Route>
    </Routes>
  );
}

export default App;
