import React from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import './HeaderStyles.css'

const Header = () => {

  const pages = [
    {
      name: "Home",
      path: "/"
    },
    {
      name: "Prices",
      path: "/login"
    },
    {
      name: "News",
      path: "/register"
    },
    {
      name: "Contact",
      path: "/register"
    },
  ]

  const [open, setOpen] = useState(false)

  // return (
  //   <header className='content-grid dark'>
  //     <div className="header">
  //       <div className="logo">DLR</div>
  //       <nav>
  //           <ul>
  //             {pages.map((page, index) => <li key={index}><Link to={page.path}>{page.name}</Link></li>)}
  //           </ul>
  //       </nav>
  //     </div>
  //   </header>
  // )

  const handleNav = () => {
    setOpen(!open)
  }
 
  return (
    <header className='content-grid dark'>
      <div className="header">
        <div className="logo">
          <a href='/'>
            <p>DAYLIGHT</p>
            <p>ROBBERY</p>
          </a>
        </div>
        {/* <button onClick={handleNav}>
          <i className={open ? 'fas fa-times' : 'fas fa-bars'}></i>
        </button>
        {open && (<nav className='mobile-nav content-grid'>
             <ul>
               {pages.map((page, index) => <li key={index}><Link to={page.path} className='btn-secondary'>{page.name}</Link></li>)}
             </ul>
         </nav>)} */}
      </div>
    </header>
  )
}

export default Header